import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

const Wrap = styled.div`
  padding: 50px;
  background-color: #f5f5f5; 
`

const Paragraph = styled.p`
  margin: 0;
`

const ScreenShot = styled.div`
  margin: 20px auto;
  max-width: 700px;
  height: 400px;
  border: 1px solid;
`

const TypeWriter = () => {
  const { formatMessage } = useIntl()
  return (
    <Wrap>
      <Paragraph>
        { formatMessage({ id: 'typewriter.line1' }) }
      </Paragraph>
      <Paragraph>
        { formatMessage({ id: 'typewriter.line2' }) }
      </Paragraph>
      <ScreenShot>
        It Should be a screenshot here
      </ScreenShot>
    </Wrap>
  )
}

export default TypeWriter