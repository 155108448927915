import React from 'react'

import LandingPage from '../../containers/LandingPage'
import usePageSetup from '../../hooks/usePageSetup'

export default () =>  {
  usePageSetup()
  return <LandingPage />
}

