import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { Layout } from '../../../../constant'
import { Sizes } from '../../../../style/constant'


const Wrap = styled.section`
  background-color: #f5f5f5; 
  padding: 30px 0;
  h3 {
    font-size: 16px;
    display: inline;
    font-weight: bold;
  }
`

const Title = styled.h2`
  margin: 20px  0 -20px;
  font-size: 40px;
  text-align: center;
  @media (max-width: ${Sizes.screen.tablet}px) {
    font-size: 28px;
    margin-bottom: 10px;
  }
`

const GridWrap = styled.section`
  padding: 50px;
  display: flex;
  max-width: ${Layout.containerWidth}px;
  margin: 0 auto;
  @media (max-width: ${Sizes.screen.tablet}px) {
    flex-direction: column;
    padding: 10px;
  }
`

const Grid = styled.div`
  flex: 1;
  padding: 0 50px 15px;
  margin: 0 15px;
  background-color: #fff;
  @media (max-width: ${Sizes.screen.tablet}px) {
    flex-direction: column;
    margin: 15px;
    padding: 0 20px;
  }

`

const GridTitle  = styled.h4`
  font-size: 22px;
  margin-bottom: 8px;
  text-align: left;
`

const GridDesc  = styled.p`
  line-height: 1.6;
  text-align: left;
  margin-top: 0;
`

const Features = () => {
  const { formatMessage } = useIntl()
  return (
    <Wrap>
      <Title>
        { formatMessage({ id: 'feature.title' }) }
      </Title>
      <GridWrap>
        <Grid>
          <GridTitle
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'feature1.title' }) }}
          />
          <GridDesc
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'feature1.desc' }) }}
          />
        </Grid>
        <Grid>
          <GridTitle>
          { formatMessage({ id: 'feature2.title' }) }
          </GridTitle>
          <GridDesc
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'feature2.desc' }) }}
          />
        </Grid>
        <Grid>
          <GridTitle>
          { formatMessage({ id: 'feature3.title' }) }
          </GridTitle>
          <GridDesc
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'feature3.desc' }) }}
          />
          {/* Set a Goal 
          plan B: Tune params to discoverMai */}
        </Grid>
      </GridWrap>
    </Wrap>
  )
}


export default Features