import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { Layout } from '../../../../constant'
import screenShotImg from '../../../../assets/images/screenshot.png'
import { Sizes } from '../../../../style/constant'

const Wrap = styled.section`
  min-height: 532px;
  box-sizing: border-box;
  max-width: ${Layout.containerWidth}px;
  margin: 0 auto;
  background-color: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 70px; 
  @media (max-width: ${Sizes.screen.tablet}px) {
    text-align: center;
  }
`

const Banner = styled.h1`
  font-size: 40px;
  line-height: 1.4;
  margin: 0;
  text-align: left;
  @media (max-width: ${Sizes.screen.tablet}px) {
    text-align: center;
  }
  @media (max-width: ${Sizes.screen.mobile}px) {
    font-size: 28px;
  }
`
const Banner2 = styled.h3`
  font-size: 40px;
  line-height: 1.4;
  margin: 0;
  text-align: left;
  @media (max-width: ${Sizes.screen.tablet}px) {
    text-align: center;
  }
  @media (max-width: ${Sizes.screen.mobile}px) {
    font-size: 28px;
  }
`

const Desc = styled.p`
  text-align: left;
  line-height: 1.8;
  @media (max-width: ${Sizes.screen.tablet}px) {
    text-align: center;
  }
`

const GetStarted = styled.a`
  background: #1890ff;
  padding: 10px 18px;
  border-radius: 2px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    background: #0880ef;
  }
  @media (max-width: ${Sizes.screen.mobile}px) {
    display: inline-block;
    margin-top: 15px;
  }
`

const ScreenShot = styled.div`
  margin: 20px auto;
  width: 100%;
  /* border: 1px solid #ddd; */
  display: flex;
  justify-content: center;
  align-items: center;
`

const ScreenShotImg = styled.img`
  width: 100%;
`

const Left = styled.div`
  flex: 0 1 500px;
`
const Right = styled.div`
  flex: 1;
  @media (max-width: ${Sizes.screen.tablet}px) {
    display: none;
  }
`

const Br = styled.br`
  @media (max-width: ${Sizes.screen.mobile}px) {
    display: none;
  }
`

const MainBanner = () => {
  const { formatMessage } = useIntl()
  return (
    <Wrap>
      <Left>
        <Banner>
          { formatMessage({ id: 'main-banner1' }) }
        </Banner>
        <Banner2>
        { formatMessage({ id: 'main-banner2' }) }
        </Banner2>
        <Desc>
          { formatMessage({ id: 'main-desc-line1' }) }<Br />
          { formatMessage({ id: 'main-desc-line2' }) }<Br />
          { formatMessage({ id: 'main-desc-line3' }) }<Br />
          { formatMessage({ id: 'main-desc-line4' }) }<Br />
          <br />
          <GetStarted href="https://visify-app.weekdays.tech/signup">
            { formatMessage({ id: 'get-started' }) }
          </GetStarted>
        </Desc>
      </Left>
      <Right>
        <ScreenShot>
          <ScreenShotImg
            alt="退休規劃、可視化現金流操作示意圖"
            src={screenShotImg}
          />
        </ScreenShot>
      </Right>
    </Wrap>
  )
}


export default MainBanner