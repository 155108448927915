import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { Layout } from '../../../../constant'
import { Sizes } from '../../../../style/constant'

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  /* box-shadow: 0 130px 50px 50px rgba(255,255,255,1); */
  margin-bottom: 40px;
  width: 100%;
`

const Inner = styled.div`
  display: flex;
  justify-content: center;
  max-width: ${Layout.containerWidth}px;
  margin: 0 auto;
  padding: 50px 50px 0;
  @media (max-width: ${Sizes.screen.tablet}px) {
    flex-direction: column;
    padding: 15px;
  }
`

const Section = styled.section`
  /* max-width: ${Layout.containerWidth / 2}px; */
  /* border: 1px solid #ccc; */
  /* background-color: #f5f5f5; */
  border-radius: 2px;
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
  line-height: 1.6;
  @media (max-width: ${Sizes.screen.tablet}px) {
    width: 100%;
  }
  br {
    @media (max-width: ${Sizes.screen.tablet}px) {
      display: none;
    }
  }
  .h2 {
    display: flex;
    font-weight: bold;
    font-size: 24px;
    margin: 0 0 10px;
  }
  h2 {
    font-size: 24px;
    margin: 0 10px 10px;
  }
  h3 {
    font-size: 16px;
    display: inline;
    font-weight: bold;
  }
`

const UseCase = () => {
  const { formatMessage } = useIntl()
  return (
    <Wrap>
      <Inner>
        <Section dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'use-case.case1' }) }}>
        </Section>
        <Section dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'use-case.case2' }) }}>
        </Section>
          {/* <SlickWrap>
            <Section>
              <Title>使用案例3</Title>
              <Paragraph>
                志明與春嬌，是一對中產階級的新婚夫妻。<br />
                擁有一間屬於自己的房子一直是他們的夢想，<br />
                但沒有富爸爸的兩個人，<br />
                對於什麼時候能買房子和能負擔多少價位的房子，<br />
                一直沒有太多的想法，日子也就一天一天地過去了<br /><br />

                使用了 visify 之後，<br />
                藉由觀察未來各個時間點的 <HightLight>收入、支出占比</HightLight> <br />
                更清楚了解自己能承擔的房屋貸款， <br />
                終於買到了適合自己價位的房子！
              </Paragraph>
            </Section>
          </SlickWrap> */}
      </Inner>
    </Wrap>
  )
}

export default UseCase


/* Slick version */

// import React from 'react'
// import styled from 'styled-components'
// import { useIntl } from 'react-intl'
// import Slider from "react-slick";

// import { Layout } from '../../../../constant'

// const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1
// };

// const Wrap = styled.div`
//   position: relative;
//   z-index: 1;
//   /* box-shadow: 0 130px 50px 50px rgba(255,255,255,1); */
//   margin-bottom: 40px;
//   width: 100%;
// `

// const Inner = styled.div`
//   max-width: ${Layout.containerWidth}px;
//   width: 100%;
//   margin: 0 auto;
//   padding: 50px 50px 0;
// `

// const SlickWrap = styled.div`
//   display: flex;
//   justify-content: center;
// `

// const Section = styled.section`
//   max-width: ${Layout.containerWidth / 2}px;
//   /* border: 1px solid #ccc; */
//   /* background-color: #f5f5f5; */
//   border-radius: 2px;
//   padding: 20px;
//   margin: 0 auto;
// `

// const Title = styled.h3`
//   font-size: 24px;
//   margin: 0 0 10px;
// `

// const HightLight = styled.span`
//   font-weight: bold;
// `

// const Paragraph = styled.p`
//   margin: 0;
//   line-height: 1.6;
// `

// const UseCase = () => {
//   const { formatMessage } = useIntl()
//   return (
//     <Wrap>
//       <Inner>
//         <Slider {...settings}>
//           <SlickWrap>
//             <Section>
//               <Title>使用案例 1</Title>
//               <Paragraph>
//                 25歲的小明，是一個節儉務實的上班族，<br />
//                 他常想著退休後簡單快樂的生活，<br />
//                 原本漫無目標的覺得大概要為公司賣命到終老吧！<br /><br />

//                 在使用了 visify 之後，<br />
//                 他調整了不同的 <HightLight>每月收入</HightLight>、<HightLight>淨資產投資報酬率</HightLight>、<HightLight>每月支出</HightLight>，<br />
//                 發現自己在45歲時淨資產就能夠因應日後的生活支出， <br />
//                 於是他定期檢示自己有無達成財務目標， <br />
//                 工作和儲蓄都更有目標幹勁了！
//               </Paragraph>
//             </Section>
//           </SlickWrap>
//           <SlickWrap>
//             <Section>
//               <Title>使用案例 2</Title>
//               <Paragraph>
//                 志明與春嬌，是一對中產階級的新婚夫妻。<br />
//                 擁有一間屬於自己的房子一直是他們的夢想，<br />
//                 但沒有富爸爸的兩個人，<br />
//                 對於什麼時候能買房子和能負擔多少價位的房子，<br />
//                 一直沒有太多的想法，日子也就一天一天地過去了<br /><br />

//                 使用了 visify 之後，<br />
//                 藉由觀察未來各個時間點的 <HightLight>收入、支出占比</HightLight> <br />
//                 更清楚了解自己能承擔的房屋貸款， <br />
//                 終於買到了適合自己價位的房子！
//               </Paragraph>
//             </Section>
//           </SlickWrap>
//           {/* <SlickWrap>
//             <Section>
//               <Title>使用案例3</Title>
//               <Paragraph>
//                 志明與春嬌，是一對中產階級的新婚夫妻。<br />
//                 擁有一間屬於自己的房子一直是他們的夢想，<br />
//                 但沒有富爸爸的兩個人，<br />
//                 對於什麼時候能買房子和能負擔多少價位的房子，<br />
//                 一直沒有太多的想法，日子也就一天一天地過去了<br /><br />

//                 使用了 visify 之後，<br />
//                 藉由觀察未來各個時間點的 <HightLight>收入、支出占比</HightLight> <br />
//                 更清楚了解自己能承擔的房屋貸款， <br />
//                 終於買到了適合自己價位的房子！
//               </Paragraph>
//             </Section>
//           </SlickWrap> */}
//         </Slider>
//       </Inner>
//     </Wrap>
//   )
// }

// export default UseCase