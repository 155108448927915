import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { Sizes } from '../../../../style/constant'

const Wrap = styled.div`
  padding: 150px 0 150px;
  background-color: #eee;
  /* color: #fff; */
  text-align: center;
	/* background: linear-gradient(-45deg, #ffd7b2, #ff9cee, #83f6ff, #83fffe); */
	/* background-size: 400% 400%; */
	/* animation: gradient 15s ease infinite; */

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
`
const Paragraph = styled.p`
  margin: 0;
  margin-bottom: 30px;
  font-size: 40px;
  @media (max-width: ${Sizes.screen.tablet}px) {
    font-size: 24px;
  }
`


const GetStarted = styled.a`
  background: #1890ff;
  padding: 10px 18px;
  border-radius: 2px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    background: #0880ef;
  }
`

const Ending = () => {
  const { formatMessage } = useIntl()
  return (
    <Wrap>
      <Paragraph>
        &#x1F680;
        &nbsp;
        { formatMessage({ id: 'ending.title' }) }
      </Paragraph>
      <GetStarted href="https://visify-app.weekdays.tech/signup">
        { formatMessage({ id: 'get-started' }) }
      </GetStarted>
    </Wrap>
  )
}

export default Ending