import React from 'react'
import styled from 'styled-components'

import Ending from './components/Ending'
import Features from './components/Features'
import MainBanner from './components/MainBanner'
import UseCase from './components/UseCase'
import TypeWriter from './components/TypeWriter'

const Wrap = styled.div``

const LandingPage = () => (
  <Wrap>
    <MainBanner />
    {/* <TypeWriter /> */}
    <Features />
    <UseCase />
    <Ending />
  </Wrap>
)

export default LandingPage