import ReactGA from 'react-ga'
import { useEffect } from 'react'

const usePageView = (gaPath = window.location.pathname + window.location.search) => {
  useEffect(() => {
    console.log('send page view of: ' + gaPath)
    ReactGA.pageview(gaPath)
  }, [gaPath])
}

export default usePageView
